/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


@font-face {font-family: "Guardian Egyptian Web Bold";
     src: url("//db.onlinewebfonts.com/t/08d78e70bb7fc6ae5cdd413384b799fb.eot");
     src: url("//db.onlinewebfonts.com/t/08d78e70bb7fc6ae5cdd413384b799fb.eot?#iefix") format("embedded-opentype"),
          url("//db.onlinewebfonts.com/t/08d78e70bb7fc6ae5cdd413384b799fb.woff2") format("woff2"),
          url("//db.onlinewebfonts.com/t/08d78e70bb7fc6ae5cdd413384b799fb.woff") format("woff"),
          url("//db.onlinewebfonts.com/t/08d78e70bb7fc6ae5cdd413384b799fb.ttf") format("truetype"),
          url("//db.onlinewebfonts.com/t/08d78e70bb7fc6ae5cdd413384b799fb.svg#Guardian Egyptian Web Bold") format("svg"); 
}